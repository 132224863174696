.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main-container {
  flex: 1;
}

.container {
  width: 100%;
  max-width: 1220px;
  padding: 0 10px;
  margin: 0 auto;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
