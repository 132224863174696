.workbench-list-item {
  margin-top: 32px;
}

.workbench-list-item:first-child {
  margin-top: 0px;
}

.workbench-add-btn-container {
  margin-top: 32px;
  margin-bottom: 64px;
  text-align: center;
}

.workbench-add-btn {
  display: inline-flex;
  align-items: center;
  height: 44px;
  padding-left: 24px;
  padding-right: 24px;
  border-radius: 8px;
  background: #111827;
  border: none;
  color: #fff;
  font-size: 16px;
  font-family: inherit;
  cursor: pointer;
  transition: background-color 100ms cubic-bezier(0.35, 0, 0.25, 1);
}

.workbench-add-btn:hover {
  background: #374151;
}
