.workbench {
  width: 100%;
  display: flex;
}

.editor-container,
.output-container {
  width: 50%;
}

.output {
  height: 300px;
  overflow-y: auto;
}

/* scrollbar */
.output::-webkit-scrollbar {
  width: 8px;
}

.output::-webkit-scrollbar-track {
  background-color: transparent;
}

.output::-webkit-scrollbar-thumb {
  border-color: transparent;
  background-color: #373f46;
  border-radius: 3px;
}

.workbench-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 18px;
  padding-right: 18px;
  height: 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.workbench-toolbar-details-container {
  display: inline-flex;
  align-items: center;
}

.workbench-toolbar-title {
  font-size: 13px;
  color: #f2f2f2;
}

.editor-name-input {
  line-height: 1;
}

.editor-name-input input {
  font-family: inherit;
  font-size: 13px;
  color: #f2f2f2;
  background-color: transparent;
  border: none;
  outline: 0;
}

.editor-name-input input:focus {
  outline: 0;
  border-bottom: 1px solid #e2e2e2;
}

.editor-container {
  background-color: #212a31;
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px 0 0 16px;
  overflow: hidden;
}

.output-container {
  background-color: #263238;
  border-left: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 0 16px 16px 0;
  overflow: hidden;
}

.workbench-action-btn-text {
  color: #9aa0a6;
  font-size: 13px;
  padding: 6px 12px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background: none;
  background-color: transparent;
  transition: color 100ms cubic-bezier(0.35, 0, 0.25, 1),
    background-color 100ms cubic-bezier(0.35, 0, 0.25, 1);
  user-select: none;
}

.workbench-action-btn-text:hover {
  color: #e8eaed;
  background-color: rgba(232, 234, 237, 0.1);
}

.animation-fade-in {
  animation: fadeIn 200ms 0ms forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

/* custom codemirror style */
.editor .CodeMirror-code,
.editor .CodeMirror pre.CodeMirror-line,
.editor .CodeMirror pre.CodeMirror-line-like {
  font-family: Menlo, monospace;
  font-size: 14px;
  line-height: 1.5;
}

.editor .CodeMirror pre.CodeMirror-line,
.editor .CodeMirror pre.CodeMirror-line-like {
  padding: 0 10px;
}

.editor .cm-s-material.CodeMirror,
.editor .cm-s-material .CodeMirror-gutters {
  background-color: #212a31;
}
